import React, { useEffect, useState } from 'react'
import slide_image_1 from '../assets/1.jpg';
import slide_image_2 from '../assets/2.jpg';
import slide_image_3 from '../assets/3.jpg';
import slide_image_4 from '../assets/4.jpg';
import slide_image_5 from '../assets/5.jpg';
import slide_image_6 from '../assets/6.jpg';
import './home.css';
import { Link } from 'react-router-dom';

function HomeScreen() {
  const slidesList = [slide_image_1, slide_image_2, slide_image_3, slide_image_4, slide_image_5, slide_image_6];

  const [backgroundImage, setBackgroundImage]                             = useState();

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    randomSlideInformation();

    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  },[])

  const randomSlideInformation = () => {
    const rand = Math.floor(Math.random() * 6);
    setBackgroundImage(slidesList[rand])
  }

  return ( 
    <div className="home-landing-outer">
        <div className="home-landing" style={{ 
                backgroundImage: `url("${backgroundImage}")` 
              }}>
          <div className="home-overlay-landing">
            <div className="cursor-circle"
                style={{
                  position: 'fixed',
                  top: `${mousePosition.y}px`,
                  left: `${mousePosition.x}px`,
                  transform: 'translate(-50%, -50%)', // Center the circle
                }}
              >
            </div>
            <div className="home-inner">
               <div className="extraction-home-content">
                  <h3 className="top-title">GET TO KNOW ABOUT THE AFRICAN STORY</h3>
                  <h1 className="main-title">AFRICAN STORY</h1>
                  <h3 className="top-title">MAKHAOLA FAMILY</h3>
                  <div className="button-row mgtop40">
                    <Link to="/person/840" className="btn btn-action btn-home">Makhaola</Link>
                  </div>
               </div>
               
            </div>
          </div>
        </div>
    </div>
  )
}

export default HomeScreen