import React from 'react'
import transitions from '../transitions';

function AboutScreen() {
  return (
    <div className="standard-page">
        <div className="screen-about"></div>
        <div className="container">
            
            <div className="info-part">
                <p>
                Welcome to Bantus, where the legacies of African royalty live on. This site is dedicated to preserving and showcasing the rich histories of African houses, Chiefs, and Chieftains through meticulously curated family trees. Each lineage we present offers a glimpse into the honor, tradition, and cultural significance that have shaped generations of leadership across the continent.
                </p>
                <p>
                    At Bantus, we believe in the power of heritage. Our goal is to provide a comprehensive and respectful representation of the royal families that have upheld their communities' values, guided by wisdom, courage, and a deep connection to their people and land. Whether you're a researcher, historian, or simply someone who cherishes the wealth of African culture, Bantus is here to bring these stories to light.
                </p>
                <p className="mega-text">
                        Join us in celebrating Africa's majestic past and the regal figures who have shaped it.
                </p>
            </div>
        </div>
    </div>
  )
}

export default transitions(AboutScreen)