import React, { useEffect, useState } from 'react'
import './tree.css';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import transitions from '../transitions';

function TreeScreen() {

    const params                                                           = useParams();

    const [featuredPerson, setFeaturedPerson]                              = useState();
    const [treeColletion, setTreeCollection]                               = useState([]);

    const navigate                                                         = useNavigate();

    useEffect(() => {
        collectInformation();
        collectInformationTreeList();
    },[])

    const collectInformation = async () => {
        try{

            const currentID = + params.id;
            const res = await axios.get(CONSTANTS.API_URL +"house/" + currentID);
            // console.log(res.data);
            setFeaturedPerson(res.data)
        }catch(err){
            console.log(err);
        }
    }
    const collectInformationTreeList = async () => {
        try{

            const currentID = + params.id;
            const res = await axios.get(CONSTANTS.API_CUST +"house/tree/" + currentID);
            console.log(res.data);
            setTreeCollection(res.data);

        }catch(err){
            console.log(err);
        }
    }
  return (
    <div className="standard-page">
        <div id="contain-area" className="space-tree">TreeScreen

            {
                featuredPerson && (
                    <ul className="tree">
                        <li>
                            <a href="#" className="tree-content-info">
                                <img src={featuredPerson.acf.image_feature.url} className="tree-image" alt={featuredPerson.title.rendered} />
                                <div className="tree-title">{featuredPerson.title.rendered}</div>
                            </a>
                            {
                                treeColletion && 
                                treeColletion.length > 0 &&(
                                    <ul>
                                        {
                                            treeColletion.map((treeItem, index) => {
                                                return  <li key={index}>
                                                            <a href="#">
                                                                <img src={treeItem.featuredImage.url} className="tree-image" alt={treeItem.title} />
                                                                <div className="tree-title">{treeItem.title}</div>
                                                                <div className="flexpart">
                                                                    <Link to={"/person/" + treeItem.id}
                                                                        className="tree-link">
                                                                        More
                                                                    </Link>
                                                                    <button 
                                                                        onClick={() =>{
                                                                            navigate('/baseline', {replace: true, state: {page:"tree", id: treeItem.id}})
                                                                        }}
                                                                        className="unbutton tree-link">
                                                                        Tree
                                                                    </button>
                                                                </div>
                                                            </a>
                                                        </li>
                                            })
                                        }                                       
                                    </ul>
                                )
                            }
                        </li>
                    </ul>
                )

            }
        </div>
    </div>
  )
}

export default transitions(TreeScreen)