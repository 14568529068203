import React from 'react'
import './top.css';
import { Link } from 'react-router-dom';

function TopNavigation() {
  return (
    <nav className="navbar navbar-expand-lg nav-custom-go txt-nav-light">
        <div className="container-fluid">
            <Link className="navbar-brand" to="/">Bantus</Link>        
            <div className="sidenavidation" id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link to={"/about"}className="nav-link active">About</Link>
                    </li>
                    <li className="nav-item">
                      {
                        /*Contact us */
                      }
                    </li>
                </ul>
            </div>
      </div>
    </nav>
  )
}

export default TopNavigation