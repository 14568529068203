import TopNavigation from "./componets/TopNavigation";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomeScreen from "./Pages/HomeScreen";
import SingleScreen from "./Pages/SingleScreen";
import AboutScreen from "./Pages/AboutScreen";
import TreeScreen from "./Pages/TreeScreen";
import BaseLineScreen from "./Pages/BaseLineScreen";

function App() {
  return (   
        <Router>
           <div className="main-base">
           <TopNavigation />
           <Routes>
               <Route path="/" element={<HomeScreen />}/>  
               <Route path="/person/:id" element={<SingleScreen />}/>  
               <Route path="/about" element={<AboutScreen />}/>  
               <Route path="/tree/:id" element={<TreeScreen />}/>
               <Route path="/baseline" element={<BaseLineScreen />} /> 
           </Routes>
          </div>
        </Router>
        
  );
}

export default App;
