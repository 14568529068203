import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import standImg from '../assets/africano-or.png';

function BaseLineScreen() {

    const location                                  = useLocation();
    const [businessPage, setBusinessPage]           = useState("");
    const [businessID, setBusinessID]               = useState("");

    const navigate                                  = useNavigate();    

    useEffect(() => {
        setBusinessPage(location.state.page);
        setBusinessID(location.state.id);
    },[]);

    
    useEffect(() => {
        if(businessPage.length > 0){
            /*
            console.log("{ *********************** }");
            console.log(businessPage);
            console.log(businessID);
            console.log("{ *********+++********* }");
            */
            navigate("/" + businessPage + "/" + businessID);
        }
    },[businessPage])
    
  return (
    <div>
        <div className="neutral-load">
        <img src={standImg} className="full-image-neutral" />
        </div>
    </div>
  )
}

export default BaseLineScreen