import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import * as CONSTANTS from "../CONSTANTS";
import { Parser } from "html-to-react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper';
import './singleperson.css';
import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa6';
import transitions from '../transitions';
import { motion, useInView } from 'framer-motion';


function SingleScreen() {

    const [featuredPerson, setFeaturedPerson]                              = useState();
    const [wifeList, setWifeList]                                          = useState([]);
    const params                                                           = useParams();
    const htmlParser = new Parser();
    const swiperRef                                                        = useRef(null);
    const [activeIndex, setActiveIndex]                                    = useState();
    const [hoveredWife, setHoveredWife]                                    = useState(null);
    const [hoverPosition, setHoverPosition]                                = useState({ top: 0, left: 0 });
    const tableRef                                                         = useRef(null);

    const navigate                                                         = useNavigate();

    useEffect(() => {
        collectInformation();
        collectChieftenList();
    },[])

    const collectInformation = async () => {
        try{

            const currentID = + params.id;
            const res = await axios.get(CONSTANTS.API_CUST +"house/" + currentID);
            console.log(res.data);
            setFeaturedPerson(res.data)
        }catch(err){
            console.log(err);
        }
    }

    const collectChieftenList = async () => {
        try{
            const currentID = + params.id;
            //console.log(currentID);
            const res = await axios.get(CONSTANTS.API_CUST +"wives/" + currentID);
            //console.log(res);
            setWifeList(res.data)
        }catch(err){
            console.log(err);
        }
    }
    
    const handleMouseEnter = (wife, event) => {
        setHoveredWife(wife);
        const rect = tableRef.current.getBoundingClientRect();
        setHoverPosition({
            top: event.clientY - rect.top,
            left: event.clientX - rect.left
        });
    };

    const handleMouseLeave = () => {
        setHoveredWife(null);
    };

  return (
    <div className="standard-page">
        <div className="container">
          Single Screen
          {
            featuredPerson && (
                <div className="outer-plane-display">
                    <div className="row top-upper">
                        <div className="col-md-6">                            
                            <div className="img-box">
                                 <img 
                                    className="house-image" 
                                    src={featuredPerson.featured_image.url}/>
                            </div> 
                            
                        </div>
                        <div className="col-md-6">
                           <h2 className="title-70">{featuredPerson.title}</h2>                            
                            <div className="bio-single">
                              {htmlParser.parse(featuredPerson.house_bio)}                       
                            </div>
                        </div>
                    </div>
                    <div className="ethnicity_group">
                        <motion.h2 
                            className="ethnicity"
                            initial={{ scale:1, opacity:1}}
                            whileInView={{scale:2, opacity:0.4}}
                            viewport={{
                                margin: "-400px"
                            }}
                            transition={{duration:1.5}}>
                            {featuredPerson.ethnicity_group}
                        </motion.h2>
                    </div>
                    {
                        wifeList && (
                            <div className="row bottom-single-content">
                               
                                <div className="col-md-12">
                                    <div className="list-sliders">
                                        <Swiper
                                             ref={swiperRef}
                                             effect={'coverflow'}                                     
                                             grabCursor={true}                                     
                                             centeredSlides={true} // Change this line                                     
                                             loop={true}                                     
                                             slidesPerView={'auto'} // Change this line                                     
                                             coverflowEffect={{                                     
                                               rotate: 0,                                     
                                               stretch: 0,                                     
                                               depth: 100,                                     
                                               modifier: 2.5,                                     
                                             }}   
                                             pagination={{ el: '.swiper-pagination', clickable: true }}
                                             navigation={{
                                               nextEl: '.swiper-button-next',
                                               prevEl: '.swiper-button-prev',
                                               clickable: true,
                                             }}                                  
                                             modules={[EffectCoverflow, Pagination, Navigation]}                                     
                                             className="swiper_container"
                        
                                            >
                                            {
                                                wifeList.map((wife, index) => {
                                                    return <SwiperSlide key={wife.id}>
                                                                  <div className="label-name-titling"> {index + 1}) {wife.title}</div>                                                                 
                                                                <img src={wife.featuredImage} alt={wife.title} />
                                                            </SwiperSlide>
                                                })
                                            }

                                                <div className="slider-controler">
                                                    <div className="swiper-button-prev slider-arrow">
                                                        <FaArrowLeft />
                                                    </div>
                                                    <div className="swiper-button-next slider-arrow">
                                                    <FaArrowRight />
                                                    </div>
                                                    
                                                </div>
                                            </Swiper>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                     
                    <div className="table-section-area">
                        <div className="content-table">
                            <div  className="container relateimg">
                            {
                                wifeList &&
                                (
                                    <table ref={tableRef} className="table tab-liner">
                                        <tbody>
                                            {
                                                wifeList.map((wife, index) => {
                                                    return <tr className="area-rows" key={index}
                                                        onMouseEnter={(event) => handleMouseEnter(wife, event)}
                                                        onMouseLeave={handleMouseLeave}>                                                        
                                                        <td ><div className="numbwife">{wife.wife_number}</div></td>
                                                        <td className="titling">{wife.title}</td>
                                                        <td className="chidred-list">{wife.children_list}</td>
                                                        <td>{
                                                             wife.excerpt.length > 0 && (<div className="extra-small">{wife.excerpt}</div>)                                                             
                                                            }</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                )                                
                            }
                            {
                                hoveredWife && (
                                            <div className="hovered-image-container"
                                            style={{ top: hoverPosition.top, left: hoverPosition.left }}>
                                                <img src={hoveredWife.featuredImage} alt={hoveredWife.title} />
                                            </div>
                                )
                                
                            }
                            </div>
                        </div>
                    </div>    

                    <div className="foot-linker-sub">                                                
                         <div className="fad-triggers" >
                            
                                    <Link 
                                        to={"/tree/" + featuredPerson.id} 
                                        className="btn btn-action mgrite3">View Tree
                                    </Link>
                                    
                                        {
                                            featuredPerson.parent_id.length > 0 && (
                                                <button 
                                                    onClick={() =>{
                                                        navigate('/baseline', {
                                                            replace: true, state: {page:"person", id: featuredPerson.parent_id}
                                                        })
                                                    }}
                                                    className="unbutton btn btn-action mgright mgleft">
                                                    View Father
                                                </button>
                                            )
                                        }
                                        {
                                            featuredPerson.sub_parent_id.length > 0 &&(
                                                <button 
                                                    className="unbutton btn btn-action mgleft">
                                                    View Mother
                                                </button>
                                            )
                                        }                          
                         </div>
                    </div>               
                </div>
            )
          }
        </div>
    </div>
  )
}

export default transitions(SingleScreen)